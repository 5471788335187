module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-image/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-192845889-1","head":false,"anonymize":true,"respectDNT":false,"pageTransitionDelay":0,"defer":false,"enableWebVitalsTracking":true,"exclude":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Müjde","short_name":"Müjde","icon":"src/images/icon.jpg","icon_options":{"purpose":"any maskable"},"start_url":"/tr/","lang":"tr","background_color":"#0070c6","theme_color":"#ffffff","display":"standalone","localize":[{"start_url":"/en/","lang":"en","name":"Müjde","short_name":"Müjde"},{"start_url":"/tk/","lang":"tk","name":"Müjde","short_name":"Müjde"},{"start_url":"/fa/","lang":"fa","name":"Müjde","short_name":"Müjde"},{"start_url":"/uz/","lang":"uz","name":"Müjde","short_name":"Müjde"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"f2e512a8e56e36aacb8fa497c91befb3"},
    },{
      plugin: require('../node_modules/gatsby-plugin-mdx/gatsby-browser.js'),
      options: {"plugins":[],"extensions":[".mdx"],"defaultLayouts":{},"gatsbyRemarkPlugins":[],"lessBabel":false,"remarkPlugins":[],"rehypePlugins":[],"mediaTypes":["text/markdown","text/x-markdown"],"root":"/opt/buildhome/repo"},
    },{
      plugin: require('../node_modules/gatsby-theme-i18n/gatsby-browser.js'),
      options: {"plugins":[],"defaultLang":"en","prefixDefault":true,"locales":"en tr tk fa uz","configPath":"/opt/buildhome/repo/i18n/config.json"},
    },{
      plugin: require('../node_modules/gatsby-theme-i18n-lingui/gatsby-browser.js'),
      options: {"plugins":[],"localeDir":"./i18n/lingui"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
