// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-offline-index-js": () => import("./../../../src/offline/index.js" /* webpackChunkName: "component---src-offline-index-js" */),
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-audio-jsx": () => import("./../../../src/pages/audio.jsx" /* webpackChunkName: "component---src-pages-audio-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-video-jsx": () => import("./../../../src/pages/video.jsx" /* webpackChunkName: "component---src-pages-video-jsx" */)
}

