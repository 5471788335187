import "./src/styles/reset.css";
import "./src/styles/global.css";

export const shouldUpdateScroll = ({ prevRouterProps }) => {
  if (!prevRouterProps) return true;

  const prevLocation = prevRouterProps.location;
  const prevPath = prevLocation.pathname;
  if (prevPath.includes("video") || prevPath.includes("audio")) {
    document.getElementById("footer-btns").scrollIntoView();
    return false;
  }
  return true;
};
